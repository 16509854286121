<template lang="pug">
div
  b-modal#modal-prevent-closing(ref="modal" title="Parameter Detail" @show="resetModal" @hidden="resetModal" @ok="handleOk")
    b-overlay(:show="isSaving" no-wrap="")
    form(ref="form" @submit.stop.prevent="handleSubmit")
      b-form-group(label="Name" label-for="name-input" invalid-feedback="Name is required" :state="nameState")
        b-form-input#name-input(v-model="data.name" :state="nameState" required="")
      b-form-group(label="Slug" label-for="slug-input" invalid-feedback="Slug is required" :state="slugState")
        b-form-input#slug-input(v-model="data.slug" :state="slugState" required="" :disabled="mode == 'edit'")
      b-form-group(label="Unit" label-for="unit-input" invalid-feedback="Unit is required" :state="unitState")
        b-form-input#unit-input(v-model="data.unit")
</template>

<script>
import * as Services from '../services.js';

export default {
  name: 'parameter-add-data-comp',
  data() {
    return {
      mode: null,
      data: {
        id: 0,
        name: '',
        slug: '',
        unit: '',
      },
      nameState: null,
      slugState: null,
      unitState: null,
      isShow: false,
      isSaving: false,
    };
  },
  mounted() {},
  watch: {
    'data.name': function(oldValue, newValue) {
      if (this.mode == 'add') {
        this.data.slug = this.slugify(this.data.name);
      }
    },
  },
  methods: {
    showModal(options) {
      this.mode = options.mode;
      if (options.mode == 'edit') {
        this.id = options.id;
        this.getData(options.id);
      }
      this.$bvModal.show('modal-prevent-closing');
    },

    getData(id) {
      if (this.mode == 'edit') {
        this.$store
          .dispatch(Services.GET_PARAMETER_DATA, {id: id})
          // go to which page after successfully login
          .then(res => {
            this.data = res.data[0];
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    save() {
      this.isSaving = true;
      this.$store
        .dispatch(Services.SAVE_PARAMETER_DATA, this.data)
        // go to which page after successfully login
        .then(res => {
          // this.data = res.data[0];
          this.isSaving = false;
          this.$bvToast.toast('Parameter Saved.', {
            title: 'Successfully',
            variant: 'success',
            solid: true,
          });
          this.$parent.reloadData();
        })
        .catch(error => {
          console.log(error);
          this.isSaving = false;
        });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.data.id = 0;
      this.data.name = '';
      this.data.slug = '';
      this.data.unit = '';
      this.nameState = null;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      //Save Data
      this.save();
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing');
      });
    },
  },
};
</script>

<style></style>
